.smileIcon {
  width: 100%;
  margin: auto;
  filter: invert(83%) sepia(10%) saturate(3013%) hue-rotate(83deg)
    brightness(86%) contrast(83%);
}

.description {
  font-weight: 650;
  font-size: 18px;
  justify-content: center;
  color: #3dcc79;
  padding: 16px 25px;
  text-align: center;
}
