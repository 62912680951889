.flexContainer {
  display: flex;
}

.loader {
  height: 70vh;
  display: grid;
  align-content: center;
  justify-content: center;
}

.titleDiv {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
}

.backIconContainer {
  margin: 12px 16px;
  display: grid;
  justify-content: center;
}

.titleContainer {
  font-size: 17px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.bodyContainer {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 26px 0;
}

.checkBtnGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 7px;
  padding-inline-start: 28px;
}

.merchantNameContainer {
  align-self: center;
  margin: 18px auto 28px;
}

.merchantName {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(251, 94, 7, 0.1);
  border-radius: 6px;
  padding: 8px 16px;
}

.merchantNameText {
  font-weight: 700;
  color: var(--primary);
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.text {
  color: #8d8d94;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}

.submitBtn {
  align-self: flex-end;
  margin: 12px 0;
  color: white;
  background-color: var(--primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 34px;
  width: auto;
  text-align: center;
  text-transform: capitalize;
  border: none;
  border-radius: 5px;
  width: fit-content;
  transition-duration: 0.1s;
  text-decoration: none;
}

.submitBtn:active {
  background-color: #ca4700;
}
