.happyContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 13px 0 16px;
}

.label {
  display: flex;
  align-items: center;
}

.optionInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid #e6e6eb;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-inline-end: 8px;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.optionInput:checked {
  background: var(--primary);
}

.optionInput:checked::before {
  content: "✔\fe0e";
  font-size: 13px;
  color: white;
}

.text {
  color: #8d8d94;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}

.smileIcon {
  margin-inline-start: 6px;
  filter: invert(45%) sepia(42%) saturate(3707%) hue-rotate(355deg)
    brightness(97%) contrast(104%);
}
