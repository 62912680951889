.errorIcon {
  filter: invert(51%) sepia(74%) saturate(1123%) hue-rotate(323deg)
    brightness(100%) contrast(103%);
}

.errorText {
  color: var(--error-color);
  font-size: 12px;
  display: inline;
  padding-inline-start: 7px;
}
