.textAreaContainer {
  width: 100%;
  padding-inline-start: 28px;
}

.text {
  color: #8d8d94;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 4px;
}

.textArea {
  resize: none;
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e6e6eb;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding: 8px;
}

.textArea:focus {
  outline: none !important;
  border: 1px solid var(--primary);
}
