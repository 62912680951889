.mainContainer {
    width: 100%;
    display: grid;
    justify-content: center;
}

.bodyEnclosingContainer {
    /* header = 56px, margin top = 20px, margin bottom = 20px */
    height: calc(100vh - 96px);
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.bodyContainer {
    width: 100%;
    border-radius: 8px;
    background-color: white;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
}

.chooseLanguageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.chooseLangBtn {
    color: white;
    background-color: var(--primary);
    border-radius: 6px;
    border: none;
    width: 28vw;
    height: 6vh;
    font-size: 2.7vh;
}

.chooseLangBtn p {
    margin: 0;
}

.chooseLangTitle {
    font-weight: 700;
    color: var(--primary);
    font-size: 5vw;
}
