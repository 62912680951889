.titleDiv {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
}

.backIconContainer {
    margin: 12px 16px;
}

.titleContainer {
    font-size: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.bodyEnclosingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 115px);
}

.bodyContainer {
    width: 100%;
    margin: 0 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: white;
    display: grid;
    justify-content: center;
    align-items: center;
}

.correctAddressTitle {
    margin: 0;
    color: var(--error-color);
    font-size: 16px;
}

.flexContainer {
    display: flex;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.orderDetailsContainer {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 12px;
    align-items: center;
}

.orderDetailTitle {
    color: var(--faint-blue);
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    margin-inline-end: 12px;
}

.orderDetailValueContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(193, 142, 89, 0.1);
    border-radius: 4px;
    padding: 0 12px;
}

.orderDetailValueText {
    font-weight: 500;
    color: var(--primary);
    margin: 8px 0;
}

.separator {
    background-color: rgba(0, 0, 0, 0.08);
    height: 1px;
    padding: 0;
    width: 100%;
}

.form {
    padding: 16px;
    display: grid;
    row-gap: 12px;
}

.submitBtn {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: var(--primary);
    width: 96%;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    border: none;
}
