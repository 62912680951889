.checkBtn {
  color: var(--primary);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid var(--primary);
  padding: 7px 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  margin-inline-end: 10px;
  margin-bottom: 9px;
  font-weight: 500;
  text-transform: capitalize;
}

.checked {
  background-color: var(--primary);
  color: white;
}
