.title {
    color: #8D8D94;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
}

.textfieldInput {
    background-color: #F4F5F7;
    border-radius: 5px;
    border: 1px solid #E6E6EB;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
}

.erroredTextFieldBorder {
    border: 1px solid var(--error-color);
}

.correctTextFieldBorder {
    border: 1px solid var(--green);
}

.errorText {
    color: var(--error-color);
    margin: 0;
    font-size: 12px;
    font-weight: 600;
}
