.checkBoxContainer {
  display: grid;
  grid-template-columns: min-content auto;
  padding: 16px 0;
}

.checkBox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px !important;
  border: 1px solid #e6e6eb;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-inline-end: 8px;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.checkBox:checked {
  background: var(--primary);
}

.checkBox:checked::before {
  content: "✔\fe0e";
  font-size: 13px;
}

.text {
  color: #8d8d94;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
}
