.dropdownMenu {
    position: absolute;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid var(--bg);
    border-radius: 6px;
    max-height: 120px;
    overflow: scroll;
    width: 100%;
    z-index: 9999;
}

.dropDownElement {
    width: 100%;
    display: grid;
    padding: 8px 16px;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid var(--bg);
    cursor: pointer;
}

.dropDownChosenItem {
    cursor: pointer;
    display: grid;
    padding: 8px 16px;
    justify-content: start;
    align-items: center;
    border: 1px solid var(--bg);
    border-radius: 6px;
    background-color: #F4F5F7;    
}

.searchField {
    width: 100%;
    position: relative;
    display: grid;
    padding: 4px 12px;
    justify-content: start;
    align-items: center;
    border: none;
    border-radius: 4px;
    background-color: var(--bg);
}

.searchField:focus {
    outline: none;
}

.noChoiseColor {
    color: gray;
}

.choiceColor {
    color: black;
}

.isErroredOutline {
    border: 1px solid var(--error-color);
    border-radius: 6px;
}

.notErroredOutline {
    border: 1px solid var(--green);
    border-radius: 6px;
}
