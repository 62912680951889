body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  /* background-color: var(--bg) !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins";
  src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-semi-bold";
  src: url(assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "Cairo";
  src: url(assets/fonts/Cairo/Cairo-Regular.ttf);
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.poppins-semi-bold {
  font-family: "Poppins-semi-bold", sans-serif;
  font-style: normal;
}

.cairo-font {
  font-family: "Cairo", sans-serif;
  font-style: normal;
}

:root {
  --bg: #e6e6eb;
  --primary: #fb5e07;
  --error-color: #ff695b;
  --faint-blue: #1f1b84;
  --green: #3dcc79;
}

.prevent-select {
  /* iOS Safari */
  -webkit-touch-callout: none;

  /* Safari */
  -webkit-user-select: none;

  /* Konqueror HTML */
  -khtml-user-select: none;

  /* Old versions of Firefox */
  -moz-user-select: none;

  /* Internet Explorer/Edge */
  -ms-user-select: none;

  /* Non-prefixed version, currentlysupported by Chrome, Edge, Opera and Firefox */
  user-select: none;
}